import React from 'react'
import PropTypes from 'prop-types'
import { Layout } from '@leshen/gatsby-theme-contentful'
import { graphql } from 'gatsby'
import { Main } from '@leshen/ui'
import OneTrustPrivacyPolicy from '../../../../gatsby-theme-frontier/src/components/OneTrustPrivacyPolicy'

const PrivacyPolicy = ({ data }) => (
  <Layout
    data={data}
    main={
      <Main>
        <OneTrustPrivacyPolicy />
      </Main>
    }
  />
)

PrivacyPolicy.propTypes = {
  data: PropTypes.shape({}).isRequired,
}

export default PrivacyPolicy

export const query = graphql`
  query OneTrustPrivacy($id: String!) {
    contentfulPage(id: { eq: $id }) {
      ...LeshenPage
    }
    site {
      ...LeshenMetadata
    }
  }
`
